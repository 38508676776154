import { useDispatch } from 'react-redux';
import logo from '../assets/header_logo.svg';
import logoSmall from '../assets/logo_circle.svg';
import { useHistory } from 'react-router-dom';
import { logout } from '../store/session';

const SignupHeader = () => {

  const history = useHistory();

  const dispatch = useDispatch();

  const handleGoHome = async (e) => {
    e.preventDefault();
    await dispatch(logout);
    history.push('/');
  }

  return (

    <div className="flex flex-row justify-start p-2 px-8 w-screen border-solid border-b-4 border-brand-primary min-h-[62px] py-4">
      <img className='cursor-pointer ml-[-4px]' onClick={handleGoHome} src={window.innerWidth > 1024 ? logo : logoSmall} alt="Unetwrk Logo" />
    </div>

  )

}

export default SignupHeader;
import ContactCard from "./ContactCard";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { deleteTier, getUserTiers, updateTier } from "../store/tiers";
import ContactShow from "./ContactShow";
import { Modal } from "../context/Modal";
import { Draggable, Droppable } from "react-beautiful-dnd";
import NewContact from "./NewContact";
import ContactDelete from "./ContactDelete";
import { getCurrentUser } from "../store/session";
import LimitReached from "./LimitReached";
import { Tooltip } from 'react-tooltip';
import toast from 'react-hot-toast';
import { BUTTON_CLASS_ADD_CONTACT, COLUMN_HEADER, columnHeader, INPUT_CLASS, TEXT_SMALL } from "../constants";
import { shortenText } from "./Utils";



const ContactColumn = ({tier, contacts, numContacts}) => {

  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser);
  const userTiers = useSelector(getUserTiers(currentUser.id));
  const [showEditTier, setShowEditTier] = useState(false);
  const [tierName, setTierName] = useState(tier.name);
  const [contact, setContact] = useState({});
  const tierContacts = contacts.filter(contact => contact.columnOrder === tier.position);
  const [showAddContact, setShowAddContact] = useState(false);
  const [showContactShow, setShowContactShow] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showLimitModal, setShowLimitModal] = useState(false);
  const tierLength = tierContacts.length;

  const handleUpdateTierName = (e) => {
    e.preventDefault();
    dispatch(updateTier({...tier, name: tierName}));
    setShowEditTier(false);
  }

  const handleAddContact = (e) => {
    e.preventDefault();
    if (currentUser.plan === 'free' && numContacts >= 10) {
      setShowLimitModal(true);
    } else {
      setShowAddContact(true);
    }
  }

  const handleDelete = (e) => {
    e.preventDefault();
    if (tierLength < 1 && tier.position > 0) {
      try {
        dispatch(deleteTier(tier.id));
        console.log(userTiers)
        Object.values(userTiers).forEach(ttier => {
          console.log(ttier.position, tier.position)
          if (ttier.position > tier.position) {
            dispatch(updateTier({id: ttier.id, position: ttier.position - 1}));
          }
        });
        toast.success(`${tier.name} column deleted.`)
      } catch (e) {
        toast.error('Error deleting or updating column.')
      }
    }
  }

  const handleKeyPress = (e) => {
    if(e.keyCode === 13){
      e.target.blur(); 
    }
}


  return (

    <div className="flex flex-col bg-pale-green p-5 pr-1 m-3 ml-0 rounded w-[300px] min-w-[300px]">
      <div className="flex flex-col bg-brand-primary p-4 mr-4 py-3 rounded text-white">
        <div className="flex flex-row justify-between text-white">
          {showEditTier ? 
          <input spellcheck="false" autoFocus id={`edit-tier-${tier.id}`} className={'drop-shadow bg-white border-none h-10 p-2 active:border-brand-primary active-border-2 hover:border-brand-primary rounded focus:outline-none focus:ring-2 focus:ring-brand-primary w-3/4 text-text-primary'} type="text" value={tierName} onKeyDown={handleKeyPress} onChange={e => setTierName(e.target.value)} onBlur={handleUpdateTierName} />  
          :
          <h3 className={COLUMN_HEADER + 'mb-3'}>{shortenText(tier.name, 18)}</h3>
          }
          {tier.position !== 0 &&
            <i className={COLUMN_HEADER + "text-white fa-solid fa-pencil cursor-pointer mt-1 hover:text-success-green"} onClick={e => setShowEditTier(true)}></i>
          }
        </div>
        <div className="flex flex-row justify-between">
          <p className={TEXT_SMALL + ' text-white mb-3'}>{tierLength} {tierLength == 1 ? 'contact' : 'contacts'}</p>
          {
            tier.position !== 0 &&
            <button className='mb-1 text-white fa-solid fa-trash-can text-xl text-background-secondary cursor-pointer disabled:cursor-default disabled:text-n8 hover:text-success-green' disabled={tierLength} onClick={handleDelete} data-tooltip-id="deleteColumn" data-tooltip-content={tierLength ? "Column must be empty to delete" : "Delete column"}>
            </button>
          }
          <Tooltip id="deleteColumn" />
        </div>
      </div>
      <button className={BUTTON_CLASS_ADD_CONTACT + "my-5 mr-4"} onClick={handleAddContact}>+ Add contact</button>

      <Droppable droppableId={tier.id.toString()} key={tier.id}>
            {(provided) => (
              <div 
                className="card-column card-column flex flex-col h-full overflow-y-auto scroll-margin-8 w-[calc(100%-2px)] pr-[6px]"
                ref={provided.innerRef} 
                {...provided.droppableProps}
              >
                {tierContacts.sort((a, b) => (b.verticalOrder + b.id / 100) - (a.verticalOrder + a.id / 100))
                .map((contact, index) => (
                  <Draggable key={contact.id} draggableId={contact.id.toString()} index={index}>
                    {(provided, snapshot) => (
                      <div 
                      ref={provided.innerRef} 
                      {...provided.draggableProps} 
                      {...provided.dragHandleProps}
                      >
                        <ContactCard snapshot={snapshot} contact={contact} setShowContactShow={setShowContactShow} showContactShow={showContactShow} setContact={setContact}/>
                      </div>
                    )}
                  </Draggable>
                ))
                }
                {provided.placeholder}
              </div>
            )}
      </Droppable>

      {showAddContact && <Modal children={<NewContact tierLength={tierLength} setShowDeleteModal={setShowDeleteModal} setShowAddContact={setShowAddContact} column={tier.position} setContact={setContact} setShowContactShow={setShowContactShow}/>}/>}
      {showContactShow && <Modal children={<ContactShow setShowContactShow={setShowContactShow} contact={contact}/>}/>}
      {showDeleteModal && <Modal children={<ContactDelete setShowDeleteModal={setShowDeleteModal} setShowAddContact={setShowAddContact}/>}/>}
      {showLimitModal && <Modal children={<LimitReached setShowLimitModal={setShowLimitModal}/>}/>}
    </div>

  )

}

export default ContactColumn;
import { useState } from "react";
import { useDispatch } from "react-redux";
import { createContact } from "../store/contacts";
import { useSelector } from "react-redux";
import { getCurrentUser } from "../store/session";
import {checkErrors} from "./Utils"; 
import { getLoading, setLoading } from "../store/loading";
import Spinner from "./Spinner";
import toast from "react-hot-toast";
import { BODY_BOLD, BUTTON_CLASS, BUTTON_CLASS_WHITE, HEADER_3, INPUT_CLASS } from "../constants";
import ContactDiscard from "./ContactDiscard";
import { Modal } from "../context/Modal";


const NewContact = ({setShowAddContact, column, setContact, setShowContactShow, tierLength}) => {


  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser);
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [title, setTitle] = useState("");
  const [linkedIn, setLinkedIn] = useState("");
  const [errors, setErrors] = useState([]);
  const [showDiscardModal, setShowDiscardModal] = useState(false);
  const loading = useSelector(getLoading);

  const validatePayload = (payload) => {
    setErrors([]);
    if (payload.name.length < 1) errors.push("Name cannot be empty.");
    return errors;
  }

  const handleCreate = async e => {
    e.preventDefault();
    const newContact = await handleAddContact(e);
    if (newContact && !errors.length) {
      setContact(newContact);
      setShowAddContact(false);
      setShowContactShow(true);
    }
  }

  const handleAddContact = async e => {
    e.preventDefault();
    // TODO: add login validation first
    dispatch(setLoading(1));
    const payload = {
      user_id: currentUser.id,
      name,
      company,
      title,
      linked_in: linkedIn,
      column_order: column,
      connection_description: '',
      date_connected: '',
      email: '',
      phone_number: '',
      vertical_order: tierLength
    }

    
    const errors = validatePayload(payload);
    if (errors.length > 0) {
      alert(errors.join("\n"));
      dispatch(setLoading(0));
      toast.error("Please fill out all required fields.");
      return;
    } else {
      let currentErrors = [];
      const newContact = await dispatch(createContact(payload))
      .catch(async (res) => {
        currentErrors = await checkErrors(res, setErrors);
      });
      dispatch(setLoading(0));
      if (currentErrors.length) {
        alert(currentErrors.join("\n"));
        toast.error("Error adding contact. Please try again.");
      } else {
        toast.success("Contact added.");
        return newContact;
      }
    }
  }

  const handleCancel = e => {
    e.preventDefault();
    setShowDiscardModal(true);
  }

  return (

    <div className="flex flex-col justify-even w-full bg-slate-200 p-5 h-full min-w-[700px] bg-background-disabled rounded border-2 border-brand-primary">
      {<ul className='mb-3'>
        {errors.map(error => <li className="text-error-red font-bold" key={error.message}>{error.message}</li>)}
      </ul>}
      <form className="flex flex-col items-center align-center w-full bg-slate-200 p-5 h-full">
        <div className="w-full flex flex-col items-start mb-4">
          <h1 className={HEADER_3}>Add contact</h1>
          <p className="text-xs italic">*required</p>
        </div>
        <div className="flex flex-row w-full mb-5 justify-between">
          <div className="flex flex-col">
            <label className={BODY_BOLD} htmlFor="name">*Name</label>
            <input autoFocus onChange={e => setName(e.target.value)} id="name" className={INPUT_CLASS + 'min-w-[290px]'} type="text" value={name}/>
          </div>
          <div className="flex flex-col">
            <label className={BODY_BOLD}>Title</label>
            <input onChange={e => setTitle(e.target.value)} className={INPUT_CLASS + 'min-w-[290px]'} type="text" value={title}/>
          </div>
        </div>
        <div className="flex flex-row w-full justify-between">
          <div className="flex flex-col">
            <label className={BODY_BOLD}>Company</label>
            <input onChange={e => setCompany(e.target.value)} className={INPUT_CLASS + 'min-w-[290px]'} type="text" value={company}/>
          </div>  
          <div className="flex flex-col">        
            <label className={BODY_BOLD}>LinkedIn profile URL</label>
            <input onChange={e => setLinkedIn(e.target.value)} className={INPUT_CLASS + 'min-w-[290px]'} type="text" calue={linkedIn}/>
          </div>
        </div>
        <div className="min-h-[40px]">
          {errors.map(error => <p className="text-error-red font-bold" key={error.message}>{error.message}</p>)}
        </div>
        <div className="flex flex-row justify-end w-full ">
          <button className={BUTTON_CLASS_WHITE + "mr-6 min-w-[150px]"} onClick={handleCancel}>Cancel</button>
          <button className={BUTTON_CLASS + "min-w-[150px] flex flex-row justify-center items-center"} disabled={!name.length} onClick={handleCreate}>
            {loading == 1 ? <Spinner/> :
            <>
              <p className="mr-3 text-white">Next</p>
              <i className="fa-solid fa-arrow-right text-white"></i>
            </>
            }
            </button>
        </div>
      </form>
      {(showDiscardModal) && <Modal children={<ContactDiscard setShowDiscardModal={setShowDiscardModal} setShowAddContact={setShowAddContact} />} />}
    </div>

  )


}

export default NewContact;